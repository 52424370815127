import React,{Component} from 'react';
import {BlogDetailsSection,BlogDetailsWrapper,BlogContent,BlogTextLayout,
    CountContainer,SocialContainer,FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,
    ImageLayout,ImageHolder,AuthorLayout,AuthorImageHolder,AuthorTextLayout,
    AuthorName,AuthorDesc,Divider,CountLeft,CountRight,AuthorSocialContainer,
    Form,FormHeading,FormLayout,FormGroup,InputText,ContactSpanErr,
    TextareaMessage,TextCenter,SubmitBtn,ContactSpanSuccess,BlogShareCol,
    RelatedPostLayout,RelatedPostHeading,RelatedPostImgHolder,RelatedPostTitle,RelatedPostDate
} from './blogdetails.style';
import {Row,Col,Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";
import ButtonArrow from "../../../components/Common/buttonarrow"

const WRNG_MSG_TIMEOUT = 3000;


class BlogDetails extends Component{

    constructor(props) {
        super(props);
        this.state = {
            contact_name:"",
            contact_email:"",
            contact_phone:"",
            contact_message:"",
            success_contact_message:"",
            errors:[]
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validateForm()
    {
        var error_flag = false;
        let errors = {};
        if(this.state.contact_name === "")
        {
            error_flag = true;
            errors['contact_name'] = "Please enter full name!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contact_email === "")
        {
            error_flag = true;
            errors['contact_email'] = "Please enter email!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contact_email !== "")
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.contact_email)) {
                error_flag = true;
                errors["contact_email"] = "Please enter valid email ID!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.contact_phone === "")
        {
            error_flag = true;
            errors["contact_phone"] = "Please enter Mobile No!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }
        if(this.state.contact_phone !== "")
        {
            var patternPhone = new RegExp(/^\d+$/);
            if(!patternPhone.test(this.state.contact_phone)) {
                error_flag = true;
                errors["contact_phone"] = "Please enter valid Mobile No!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
            if(this.state.contact_phone.length<10) {
                error_flag = true;
                errors["contact_phone"] = "Please enter valid Mobile No!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.contact_message === "")
        {
            error_flag = true;
            errors["contact_message"] = "Please enter message!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        this.setState({
            errors: errors
        });
        return error_flag;
    }

    contactUsSubmit = (e) =>{
        e.preventDefault();
        if(!this.validateForm())
        {
            //SUCCESS
            //TO DO
            console.log("Name: "+this.state.contact_name);
            console.log("Email: "+this.state.contact_email);
            console.log("Phone: "+this.state.contact_phone);
            console.log("Message: "+this.state.contact_message);
            this.setState({
                contact_name:"",
                contact_email:"",
                contact_phone:"",
                contact_message:"",
                success_contact_message:"Thanks for contacting us.",
                errors:[]
            },()=>{
                setTimeout(() => {
                    this.setState({
                        success_contact_message:""
                    });
               },WRNG_MSG_TIMEOUT);
            });
        }
    }

  render() {
    return (
        <BlogDetailsSection>
            <Container>
                <Row>
                    <Col md={12}>
                        <BlogDetailsWrapper>
                            <BlogContent>
                                <BlogTextLayout>
                                    <CountContainer>
                                        <CountLeft>
                                            <span>
                                            <img alt="" src={this.props.BlogData.UserImage}  />
                                            <p>{this.props.BlogData.UserText}</p>
                                            </span>
                                            <span>
                                            <img alt="" src={this.props.BlogData.CommentImage} />
                                            <p>{this.props.BlogData.CommentText}</p>
                                            </span>
                                        </CountLeft>
                                      <CountRight>
                                         <p>{this.props.BlogData.Category}</p>
                                      </CountRight>
                                    </CountContainer>

                                    <p>
                                     {this.props.BlogData.BlogText1}
                                    </p>
                                    <p>
                                    {this.props.BlogData.BlogText2}
                                    </p>

                                    <ImageLayout>
                                        <ImageHolder>
                                            <GatsImg
                                                fluid={this.props.BlogData.BlogImage1.childImageSharp.fluid}
                                                alt=""
                                            />
                                        </ImageHolder>
                                        <ImageHolder>
                                            <GatsImg
                                                fluid={this.props.BlogData.BlogImage2.childImageSharp.fluid}
                                                alt=""
                                            />
                                        </ImageHolder>
                                    </ImageLayout>

                                    <p>
                                    {this.props.BlogData.BlogText3}
                                    </p>

                                    <ul>
                                        <li>
                                            <img alt="" src={this.props.BlogData.BlogText4Icon}/>
                                            <p>
                                                {this.props.BlogData.BlogText4[0].Text}
                                            </p>
                                        </li>
                                        <li>
                                            <img alt="" src={this.props.BlogData.BlogText4Icon}/>
                                            <p>
                                                {this.props.BlogData.BlogText4[1].Text}
                                            </p>
                                        </li>
                                        <li>
                                            <img alt="" src={this.props.BlogData.BlogText4Icon}/>
                                            <p>
                                                {this.props.BlogData.BlogText4[2].Text}
                                            </p>
                                        </li>
                                        <li>
                                            <img alt="" src={this.props.BlogData.BlogText4Icon}/>
                                            <p>
                                                {this.props.BlogData.BlogText4[3].Text}
                                            </p>
                                        </li>
                                    </ul>

                                   <BlogShareCol>
                                        <p>
                                            {this.props.BlogData.BlogDate}
                                        </p>
                                        <SocialContainer>
                                            <a className="blog-fb" href="/" aria-label="Facebook Link">
                                                <FbIcon/>
                                            </a>
                                            <a className="blog-twt" href="/" aria-label="Twitter Link">
                                                <TwitterIcon/>
                                            </a>
                                            <a className="blog-linkin" href="/" aria-label="Linkedin Link">
                                                <LinkedinIcon/>
                                            </a>
                                            <a className="blog-insta" href="/" aria-label="Instagram Link">
                                                <InstaIcon/>
                                            </a>
                                        </SocialContainer>
                                    </BlogShareCol>

                                </BlogTextLayout>
                            </BlogContent>

                            <Divider/>

                            <AuthorLayout>
                                <AuthorImageHolder>
                                    <GatsImg
                                        fluid={this.props.BlogData.AuthorImage.childImageSharp.fluid}
                                        alt=""
                                    />
                                </AuthorImageHolder>
                                <AuthorTextLayout>
                                    <AuthorName>
                                        {this.props.BlogData.AuthorName}
                                    </AuthorName>
                                    <AuthorDesc>
                                        {this.props.BlogData.AuthorDesc}
                                    </AuthorDesc>
                                    <AuthorSocialContainer>
                                        <a className="auth-fb" href="/" aria-label="Facebook Link">
                                            <FbIcon/>
                                        </a>
                                        <a className="auth-twt" href="/" aria-label="Twitter Link">
                                            <TwitterIcon/>
                                        </a>
                                        <a className="auth-linkin" href="/" aria-label="Linkedin Link">
                                            <LinkedinIcon/>
                                        </a>
                                        <a className="auth-insta" href="/" aria-label="Instagram Link">
                                            <InstaIcon/>
                                        </a>
                                    </AuthorSocialContainer>
                                </AuthorTextLayout>
                            </AuthorLayout>

                            <Divider/>

                            <FormLayout>
                                <Form method="post">
                                    <FormHeading>{this.props.BlogData.FormHeading}</FormHeading>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <InputText aria-label="Name" type="text" placeholder="Name"  name="contact_name" onChange={this.handleChange} value={this.state.contact_name}/>
                                                <ContactSpanErr>{this.state.errors.contact_name}</ContactSpanErr>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <InputText aria-label="Phone" type="tel" placeholder="Phone"  name="contact_phone" onChange={this.handleChange} value={this.state.contact_phone}/>
                                                <ContactSpanErr>{this.state.errors.contact_phone}</ContactSpanErr>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <InputText aria-label="Email" type="email" placeholder="Email"  name="contact_email" onChange={this.handleChange} value={this.state.contact_email}/>
                                                <ContactSpanErr>{this.state.errors.contact_email}</ContactSpanErr>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <TextareaMessage aria-label="Comments" type="text" placeholder="Comments" name="contact_message" onChange={this.handleChange} value={this.state.contact_message}></TextareaMessage>
                                                <ContactSpanErr>{this.state.errors.contact_message}</ContactSpanErr>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <TextCenter>
                                                <SubmitBtn href="/" onClick={this.contactUsSubmit}><span>Submit</span> <ButtonArrow/></SubmitBtn>
                                            </TextCenter>
                                            <ContactSpanSuccess>{this.state.success_contact_message}</ContactSpanSuccess>
                                        </Col>
                                    </Row>
                                </Form>
                            </FormLayout>

                            <Divider/>

                            <RelatedPostLayout>
                                <RelatedPostHeading>
                                    {this.props.BlogData.RelatedPostHeading}
                                </RelatedPostHeading>
                                <Row>
                                    {
                                        this.props.BlogData.RelatedPostDetails.map(item=>{
                                            return <Col md={4}>
                                                <RelatedPostImgHolder>
                                                    <GatsImg
                                                        fluid={item.RelatedPostImg.childImageSharp.fluid}
                                                        alt=""
                                                    />
                                                </RelatedPostImgHolder>
                                                <RelatedPostTitle href="">
                                                    {item.RelatedPostTitle}
                                                </RelatedPostTitle>
                                                <RelatedPostDate>
                                                    {item.RelatedPostDate}
                                                </RelatedPostDate>
                                            </Col>
                                        })
                                    }


                                </Row>
                            </RelatedPostLayout>
                        </BlogDetailsWrapper>
                    </Col>
                </Row>
            </Container>
        </BlogDetailsSection>
        );
    }
}

const BlogDetailSection = () => (
    <StaticQuery
        query={graphql`
            query {
                blogPage2Json{
                    BlogDetails{
                        BlogDate
                        BlogHeading
                        UserImage
                        UserText
                        CommentImage
                        CommentText
                        Category
                        BlogText1
                        BlogText2
                        BlogImage1{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        BlogImage2{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        BlogText3
                        AuthorImage{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        BlogText4Icon
                        BlogText4{
                            Text
                        }
                        AuthorName
                        AuthorDesc
                        FormHeading
                        RelatedPostHeading
                        RelatedPostDetails{
                            RelatedPostImg{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            RelatedPostTitle
                            RelatedPostDate
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <BlogDetails BlogData={data.blogPage2Json.BlogDetails}/>
        )}
    />
  )
  export default BlogDetailSection;