import styled from 'styled-components';
import {device} from '../../components/Common/device';

export const BannerSection = styled.section`
    background-image:url(${props => props.BackgroundImg});
    padding:70px 0px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height:450px;
    display:flex;
    align-items:center;

    @media ${device.tablet} {
        background-size:cover;
        padding:80px 0px;
    }
`;

export const BannerContents = styled.div`
    /* padding-top:70px; */

    @media ${device.tablet} {
        display:block;
    }

`;
export const BannerOverlay = styled.div`
    position:absolute;
    background: rgba(0,0,0,.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height:450px;
    display:flex;
    align-items:center;
`;

export const BannerContentCenter = styled.div`
    max-width: 1000px;
    margin: auto;
    text-align: center;
    position:relative;

    h3{
        font-size:50px;
        font-weight:700;
        margin:0px 0px 20px;
        line-height: 50px;
        color:#fff;

        @media ${device.tablet} {
            width:100%;
            font-size: 40px;
            line-height:44px;
        }

        @media ${device.mobileXL} {
            width:100%;
            font-size: 36px;
            line-height:40px;
        }
    }

    p{
        color:#fff;
    }
    @media ${device.laptop} {
        width:100%;
        padding: 10px;
    }

    a{
        font-size:16px;
        color:#fff;

        :hover{
            color:#fff;
        }
    }
`;