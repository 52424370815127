import React from 'react';
import {BannerSection,
    BannerContents,
    BannerContentCenter,
    BannerOverlay
} from './banner.style'
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Banner = (props) => {
    return (
        <BannerSection id="homeContainer" BackgroundImg={props.BackgroundImg} >
            <BannerOverlay>
                <Container>
                    <BannerContents>
                        <BannerContentCenter>
                            <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                        </BannerContentCenter>
                    </BannerContents>
                </Container>
            </BannerOverlay>
        </BannerSection>
    )
}

export default Banner;
