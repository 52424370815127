import React from "react";
import Banner from "../containers/BlogBanner";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Footer from "../components/Footer";
import BlogDetails from "../containers/BlogPage2/BlogDetails";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImg from '../assets/blog-2-images/BlogDetails/banner.jpg';

const BlogDetailsPage = () => (
    <Layout>
        <SEO
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS />
        <Banner
            BackgroundImg={BackgroundImg}
            pageTitle="Blog Details"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="Blog Details"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/blogdetails-2"
        />
        <BlogDetails />
        <Footer templateType="type2"/>
    </Layout>
)

export default BlogDetailsPage;
