import styled from 'styled-components';
import {CommonbtnRound,AnchorLink} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device';

import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'

export const BlogDetailsSection = styled.section`

    position: relative;
    top: -60px;

    @media ${device.tablet} {
        padding-bottom: 40px;
    }
`;

export const BlogDetailsWrapper = styled.div`

    padding: 60px;
    background:#fafafa;
    @media ${device.laptop} {
        padding: 30px;
    }
    @media ${device.mobileXL} {
        padding: 20px;
    }
`;

export const BlogContent = styled.div`

`;

export const BlogTextLayout = styled.div`
    ul{
        margin:0px;
        padding:0px;
        margin-bottom:30px;
    }
    ul li{
        display:flex;
        align-items:flex-start;
    }
    ul li img{
        width: 12px;
        margin-top: 5px;
        margin-right: 10px;
    }
`;

export const CountContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:20px;

    @media ${device.mobileXL}{
        flex-direction:column;
    }
`;

export const CountLeft = styled.div`
    display:flex;

    img{
        height:20px;
        margin-right:8px;
    }
    span{
        display:flex;
        align-items:center;
        line-height:1;
        margin-right:10px;
    }
    span p{
        margin-bottom:0px;
    }

    @media ${device.tablet} {
        margin-bottom:20px;
    }
`;

export const CountRight = styled.div`
    p{
        margin-bottom:0px;
        background: #fbe8e4;
        color: #ff3b1b;
        padding: 5px 8px;
        font-size: 14px;
    }
`;

export const ImageLayout = styled.div`
    display:flex;
    justify-content:space-between;
    margin-bottom:30px;
`;

export const ImageHolder = styled.div`
    width:49%;
`;

export const BlogShareCol = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;

    @media ${device.tablet} {
        flex-direction:column;
        justify-content:center;
    }

    p{
        margin-bottom:0px;
        color:#333;
        font-weight:bold;

        @media ${device.tablet} {
            margin-bottom:20px;
        }
    }
`;

export const SocialContainer = styled.div`
    text-align:left;
    @media ${device.tablet} {
        text-align:center;
    }

    .blog-fb svg{
        width: 32px;
        color: #fff;
        padding: 6px;
        border-radius: 100%;
        margin: 0px 8px;
        background: #3949aa;
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .blog-twt svg{
        width: 32px;
        color: #fff;
        padding: 6px;
        border-radius: 100%;
        margin: 0px 8px;
        background: #00aeee;
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .blog-linkin svg{
        width: 32px;
        color: #fff;
        padding: 6px;
        border-radius: 100%;
        margin: 0px 8px;
        background: #2e318f;
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .blog-insta svg{
        width: 32px;
        color: #fff;
        padding: 6px;
        border-radius: 100%;
        margin: 0px 8px;
        background: #ec008c;
        -webkit-transition: all .5s;
        transition: all .5s;
    }

    a svg:hover{
        transform:rotate(40deg);
    }
`;

export const InstaIcon = styled(SocialInstagram)`

`;

export const LinkedinIcon = styled(SocialLinkedin)`

`;

export const TwitterIcon = styled(SocialTwitter)`

`;

export const FbIcon = styled(SocialFacebook)`

`;

export const AuthorSocialContainer = styled.div`
    text-align:left;
    @media ${device.tablet} {
        text-align:left;
    }
    a{
        margin-right:5px;
    }
    svg{
        transition: all .5s;
    }

    .auth-fb svg{
        width:25px;
        color: #3949aa;
    }
    .auth-twt svg{
        width:25px;
        color: #00aeee;
    }
    .auth-linkin svg{
        width:25px;
        color: #2e318f;
    }
    .auth-insta svg{
        width:25px;
        color: #ec008c;
    }

    a svg:hover{
        transform:rotate(40deg);
        transition: all .5s;
    }
`;

export const AuthorLayout = styled.div`
    display:flex;
    align-items:flex-start;

    @media ${device.mobileXL} {
        flex-direction:column
    }
`;
export const AuthorImageHolder = styled.div`
    width:170px;
    flex-shrink:0;
    border-radius:5px;

    @media ${device.tablet} {
        width:120px;
    }

    @media ${device.mobileXL} {
        width:100px;
    }
`;

export const AuthorTextLayout = styled.div`
    margin-left:20px;

    @media ${device.tablet} {
        margin-left:10px;
    }

    @media ${device.mobileXL} {
        margin-left:0px;
        margin-top:10px;
    }
`;
export const AuthorName = styled.h4`
    text-align:left;
    font-size:24px;
    margin-bottom:5px;
`;
export const AuthorDesc = styled.p`
    text-align:left;
    margin-bottom:10px;
`;

export const Divider = styled.hr`
    margin:30px 0px;
`;

export const FormLayout = styled.div`

`;

export const Form = styled.form`
    margin-bottom:0px;
`;

export const FormHeading = styled.h4`

`;

export const FormGroup = styled.div`
    margin-bottom:30px;
`;

export const InputText = styled.input`
    width: 100%;
    border: none;
    border:1px solid #ccc;
    padding: 8px;
    border-radius:4px;
    outline:0;
    font-size: 16px;
`;

export const ContactSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const TextareaMessage = styled.textarea`
    width: 100%;
    border: none;
    border:1px solid #ccc;
    padding: 8px;
    border-radius:4px;
    height:100px;
    outline:0;
    font-size: 16px;
`;

export const TextCenter = styled.div`
    text-align:left;
`;

export const SubmitBtn = styled(CommonbtnRound)`
    font-weight:bold;
    border-radius:25px;
    display:inline-flex;
    align-items:center;

    svg{
        width:17px;
        margin-left:10px;
    }
    :hover{
        color:#ff3c1c;
        text-decoration:none;
    }
    :hover svg path{
        fill:#ff3c1c;
    }
`;

export const ContactSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;


export const RelatedPostLayout = styled.div`

`;
export const RelatedPostHeading = styled.h4`
    text-align:left;
`;
export const RelatedPostImgHolder = styled.div`
    margin-bottom:20px;
    margin-top:20px;
    border-radius:4px;

    img{
        border-radius:4px;
    }
`;
export const RelatedPostTitle = styled(AnchorLink)`
    text-align:left;
    font-size:20px;
    line-height:25px;
    margin-bottom:5px;
    display:block;
`;

export const RelatedPostDate = styled.p`
    color:red;
    margin-bottom:0;
`;
